import type {DOMAttributes} from '@react-types/shared';

import {CSSStyles} from '../../../types/CSSStyles';
import useAppTheme from '../hooks/useAppTheme';
import {ContentColor} from '../theme/useContentPalette';
import BodyText from '../typography/BodyText';
import DetailsText from '../typography/DetailsText';
import Typography, {Size} from '../typography/Typography';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly errorMessage: React.ReactNode;
  readonly label: React.ReactNode;
  readonly ariaLabel?: string;
  readonly children?: React.ReactNode;
  readonly description?: React.ReactNode;
  readonly descriptionProps?: DOMAttributes;
  readonly errorMessageProps?: DOMAttributes;
  readonly isDisabled?: boolean | null;
  readonly isInvalid?: boolean | null;
  readonly isRequired?: boolean | null;
  readonly labelID?: string;
  readonly labelProps?: DOMAttributes;
  readonly rootProps?: DOMAttributes;
  readonly sx?: CSSStyles;
}

export default function FormElement(props: Props): JSX.Element {
  const {
    ariaLabel,
    children,
    description,
    descriptionProps,
    errorMessage,
    errorMessageProps,
    isDisabled,
    isInvalid,
    isRequired,
    label,
    labelID,
    labelProps,
    rootProps,
    sx: rootStyle,
  } = props;
  const {spacing} = useAppTheme();
  const requiredStar = isRequired ? '*' : null;
  const textColor = isDisabled ? ContentColor.DISABLED : ContentColor.PRIMARY;

  let subText = description && (
    <Typography
      color={textColor}
      css={css({marginTop: spacing.x4})}
      size={Size.XS}
      variant="div"
      {...descriptionProps}
    >
      {description}
    </Typography>
  );
  if (isInvalid && errorMessage) {
    subText = (
      <div
        css={css({
          marginTop: spacing.x8,
        })}
      >
        <DetailsText
          color={ContentColor.ERROR}
          {...errorMessageProps}
        >
          {errorMessage}
        </DetailsText>
      </div>
    );
  }

  return (
    <div
      aria-label={ariaLabel}
      css={[rootStyle]}
      {...rootProps}
    >
      {label && (
        <BodyText
          color={textColor}
          display="block"
          id={labelID}
          size="S"
          sx={css({marginBottom: spacing.x8})}
          {...labelProps}
        >
          <span>{label}</span>
          <span> </span>
          <span>{requiredStar}</span>
        </BodyText>
      )}

      {children}
      <span>{subText}</span>
    </div>
  );
}
